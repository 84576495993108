import './css/style.css';

import { BrowserRouter, Link, NavLink, Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, createRef, useState, useRef } from 'react';

import _ from 'underscore';

import FrontPage from './components/FrontPage';
import ImagePreloader from './components/ImagePreloader';
import Footer from './components/Footer';
import FrontPageContent from './components/FrontPageContent';

import { PageContent, Icon, SkewedImage, FlipImage, HighlightCenterImage } from './components/Elements';

import logo from './img/arnastofnun-logo.png';
import PlinthMenu from './components/PlinthMenu';

import config from './config';
import LangSwitch from './components/LangSwitch';
import Page from './components/Page';

function App() {
	const [siteData, setSiteData] = useState(null);
	const frontPageData = [
        {
			"name_is": "Um sýninguna",
            "name_en": "About the Exhibition",
            "in_menu": true,
            "ref_name": "aboutRef",
            "content_is": <div className="text-content">
				<h2>Heimur handritanna opnast</h2>
				<div className="md:grid grid-cols-2 gap-6 mb-6">
					<div>
						<div className="lead">
							<p>Ný sýning verður opnuð í Eddu vikuna 16. til 24. nóvember 2024. Þá gefst fólki kostur á að sjá íslensku handritin sem geyma dýrmætan menningararf. Þar eru fornar sögur og fræg kvæði en einnig ýmsir aðrir textar sem spegla hugmyndir fyrri kynslóða um líf sitt og samfélag. Á sýningunni er kappkostað að opna þennan fjölbreytta heim handritanna fyrir gestum.</p>
						</div>
						<p>Aðaláhersla sýningarinnar er á ríkulegt innihald handritanna, bæði texta og myndir, þar sem líf, dauði, tilfinningar og trú, völd og heiður koma við sögu. Fjallað verður um erlend áhrif á íslenska menningu á miðöldum og á íslenska tungu en einnig um áhrif íslenskra fornbókmennta erlendis.</p>

						<p>Sýningin kjarnast um sköpun: sköpun heims, sköpun bókmennta og handrita og þá sísköpun sem býr í tungumálinu. Fjölbreytt miðlun er notuð til að koma efninu á framfæri, en fyrir utan að skoða handritin sjálf geta gestir sökkt sér ofan í texta og snertiskjái, horft á kvikmyndir, hlustað á upplestur af ýmsu tagi og brugðið á leik.</p>

						<p>Sýningin verður opin kl. <strong>10–17</strong> alla daga vikunnar nema mánudaga.</p>
					</div>
					<div>
						<img className="rounded" src="/img/edda_upplyst.jpg" />
					</div>
				</div>
			</div>,
			"content_en": <div className="text-content">
				<h2>The World of the Manuscripts Opens</h2>
				<div className="lead">
					<p>A new exhibition will be opened in Edda from November 16 to 24, 2024. There, visitors will have the opportunity to view the Icelandic manuscripts – the nation’s most precious cultural heritage. The manuscripts contain many ancient and renowned sagas and poems, but also various other texts that reflect how previous generations thought about their lives and their society. The exhibition will strive to present visitors with the multifaceted world found within the manuscripts.</p>
				</div>
				<p>The exhibition’s primary focus will be on presenting the rich and complex world of the manuscripts, where life and death, passion and religion, and honour and power all come into play. The exhibition will look at how influences from overseas left their mark on the culture of Icelandic mediaeval society and the Icelandic language, but it will also consider the influence that Icelandic literature has had in other countries.</p>

				<p>Creation will be a major theme of the exhibition; both the creation of the manuscripts and the world surrounding them, but also the constant act of creation that is instilled in the language itself. Various media is used to present the material. In addition to viewing the manuscripts themselves, visitors can explore texts and touch screens, watch videos, listen to readings of all sorts and take part in interactive play.</p>

			</div>
		}
		/*
        {
			"name_is": "Staðsetning",
            "name_en": "Location",
            "in_menu": true,
            "ref_name": "locationRef",
            "content_is": <div className="text-content">
				<h2>Staðsetning</h2>
				<div className="md:grid grid-cols-2 gap-8 mb-6">
					<div>
						<div className="lead">
							<p>Sýningin Heimur í orðum er á 1. hæð í Eddu við Arngrímsgötu 5 í Reykjavík.<br/>Edda er mitt á milli fjögurra þekktra bygginga: Þjóðarbókhlöðunnar, Þjóðminjasafnsins, Aðalbyggingar Háskóla Íslands og Sögu.</p>
						</div>
						<p>Fyrir framan húsið eru tvö bílastæði fyrir hreyfihamlaða. Fólk í hjólastólum á greiða leið inn í húsið og um sýninguna.</p>
						<p>Sjá staðsetningu á korti hér fyrir neðan eða á <a href="https://www.google.com/maps/place/Edda/@64.1417558,-21.9530335,594m/data=!3m2!1e3!4b1!4m6!3m5!1s0x48d60b3050685bf9:0x4494460682e2f347!8m2!3d64.1417535!4d-21.9504532!16s%2Fg%2F1jkxxhzc9?entry=ttu&g_ep=EgoyMDI0MTAwNS4yIKXMDSoASAFQAw%3D%3D">Google Maps</a>.</p>
						</div>
					<img className="rounded" src="/img/edda_upplyst.jpg" />
				</div>
				<iframe frameborder="0" width="100%" height="450" src="//ginnungagap.arnastofnun.is/vefkort/embed.html#64.14206060205365,-21.950431466102604,Edda<br/>Arngrímsgötu 5<br/>107 Reykjavík,orange,14"></iframe>
			</div>,
            "content_en": <div className="text-content">
				<h2>Location</h2>
				<div className="md:grid grid-cols-2 gap-6 mb-6">
					<div>
						<div className="lead">
							<p>The exhibition World in Words is to be found on the ground floor of the Edda building in Reykjavík, which is located on 5 Arngrímsgata.<br/>Edda stands alongside four famous buildings: The Icelandic National and University Library, The Icelandic National Museum, the University of Iceland’s main building and the university’s Saga building.</p>
						</div>
						<p>The building and the museum are wheelchair accessible and there are two parking spots for people with reduced mobility directly in front of the main entrance.</p>
						</div>
					<img className="rounded" src="/img/edda_upplyst.jpg" />
				</div>
				<iframe frameborder="0" width="100%" height="450" src="//ginnungagap.arnastofnun.is/vefkort/embed.html#64.14206060205365,-21.950431466102604,Edda<br/>Arngrímsgötu 5<br/>107 Reykjavík,orange,14"></iframe>
			</div>

		}
		*/
	];

	const headerButtons = useRef(null);

	const [windowPos, setWindowPos] = useState(null);
	const [lightIntensity, setLightIntensity] = useState(0);
	const [currentLang, setCurrentLang] = useState('is');

	const [hoverBg, setHoverBg] = useState(null);

	const [initialized, setInitialized] = useState(true);

	const [themeColor, setThemeColor] = useState(null);

	const [showExtraMenu, setShowExtraMenu] = useState(false);

	const navigate = useNavigate();
	const location = useLocation();

	const frontPageSectionRef = useRef({});

	const isScrolledIntoView = (el) => {
		var rect = el.getBoundingClientRect();
		var elemTop = rect.top;
		var elemBottom = rect.bottom;
	
		var isVisible = (elemTop+100 >= 0) && (elemBottom <= window.innerHeight);

		return isVisible;
	}

	useEffect(() => {
		if (!window.windowMouseMoveHandler) {
			window.windowMouseMoveHandler = (event) => {
				setWindowPos({
					x: event.clientX,
					y: event.clientY
				});
			};

			window.addEventListener('mousemove', window.windowMouseMoveHandler);
		}

		setTimeout(() => setInitialized(true), 1300);

		setTimeout(() => {
			if (!window.windowScrollHandler) {
				window.windowScrollHandler = () => {
					if (headerButtons.current) {
						setShowExtraMenu(!isScrolledIntoView(headerButtons.current))
					}
				};

				window.addEventListener('scroll', window.windowScrollHandler, true);
			}
		}, 500);

		try {
			if (window.localStorage.getItem('currentLang')) {
				setCurrentLang(window.localStorage.getItem('currentLang'));
			}
			else {
				window.localStorage.setItem('currentLang', 'is');
			}
		}
		catch(e) {}

	}, []);

	useEffect(() => {
		if (location.pathname == '/') {
			setThemeColor(null);
		}

		window.scrollTo({top: 0, behavior: 'smooth'});
	}, [location]);

	const scrollToElement = (event, refName) => {
		event.preventDefault();
		frontPageSectionRef.current[refName].scrollIntoView({
			behavior: 'smooth'
		});
	};

	const setLang = (lang) => {
		setCurrentLang(lang);

		try {
			window.localStorage.setItem('currentLang', lang);
		}
		catch(e) {}
	}

	const urlBits = (url) => _.compact(url.split('/'));

	return <div className={'wrapper level-'+(urlBits(location.pathname).length)+' section-'+urlBits(location.pathname)[0]}>
			<div className={'app relative'}>

			<div className="right-border bg-sam_red transition-all duration-[800ms] " style={themeColor ? {
				'backgroundColor': themeColor
			} : null}>
				<div className="upper bg-sam_red transition-all duration-[800ms] " style={themeColor ? {
					'backgroundColor': themeColor
				} : null}></div>
				<div className="lower bg-sam_red transition-all duration-[800ms] " style={themeColor ? {
					'backgroundColor': themeColor
				} : null}></div>
			</div>

			<div className="absolute top-5 left-[64px] md:left-[120px] z-20 md:flex items-center">

				<Link to={'/'}><img src={logo} className="w-[200px]" /></Link>

				<div className={'hidden sm:block absolute left-[210px] w-full md:border-l border-white/50 text-white -mt-2 md:mt-2 ml-4 md:ml-5 md:pl-8 text-xl transition-[opacity,transform] duration-500'+(((!location.pathname.startsWith('/'+config.exhibitionPageRoot) && location.pathname != '/') || location.pathname  == '/'+config.exhibitionPageRoot) ? '' : ' translate-y-10 opacity-0')}><Link to="/">{window.l('Forsíða')}</Link></div>
				<div className={'hidden sm:block absolute left-[210px] w-full md:border-l border-white/50 text-white -mt-2 md:mt-2 ml-4 md:ml-5 md:pl-8 text-xl transition-[opacity,transform] duration-500'+(location.pathname.startsWith('/'+config.exhibitionPageRoot) && location.pathname != '/'+config.exhibitionPageRoot ? '' : ' translate-y-10 opacity-0')}><Link to={'/'+config.exhibitionPageRoot}>{window.l('Heimur í orðum')}</Link></div>

			</div>

			<div className="absolute top-12 right-12 z-20">
			<LangSwitch className="xl:ml-10 tab underlined" showLangNames={true} currentLang={currentLang} onLangChange={(lang) => {
											setLang(lang);
										}} />

			</div>

			<Routes>

				<Route exact path="/" element={<>
					<FrontPage initialized={initialized} 
						currentLang={currentLang} 
						windowPos={windowPos}
						showContent={true}
						isFront={true}
						tiltMode={1}
						largeHeader={true}
						headerContent={<>

							<div className={'hidden container text-white mx-auto mt-10 z-10 transition-[opacity,transform] duration-[300ms] '+(initialized ? ' opacity-100' : ' opacity-0')}>
								<div className={'inner-container'}>
									

									<div className="page-header-buttons" ref={headerButtons}>
										{
											/*
											frontPageData && _.where(frontPageData, {in_menu: true}).map((item, index) => <a className="tab underlined" 
												onClick={(event) => {
													if (item.ref_name) {
														scrollToElement(event, item.ref_name);
													}
												}}>{item['name_'+currentLang]}</a>)
											*/
										}
										<LangSwitch className="xl:ml-10 tab underlined" showLangNames={true} currentLang={currentLang} onLangChange={(lang) => {
											setLang(lang);
										}} />

									</div>
								</div>
							</div>

						</>}
					>
						{
						/*
						<div className={'hidden page-header-buttons bg-sam_light/80 dark sticky top-0 py-5 z-10'+(showExtraMenu ? ' opacity-100' : ' opacity-0 pointer-events-none')}>
							{
								frontPageData && _.where(frontPageData, {in_menu: true}).map((item, index) => <a className="tab dark underlined" 
									onClick={(event) => {
										if (item.ref_name) {
											scrollToElement(event, item.ref_name);
										}
									}}>{item['name_'+currentLang]}</a>)
							}
							<LangSwitch className="xl:ml-10 tab dark underlined" showLangNames={true} currentLang={currentLang} onLangChange={(lang) => {
								setLang(lang);
							}} />
						</div>
						*/
						}

						<FrontPageContent data={frontPageData} currentLang={currentLang} refObj={frontPageSectionRef} />
					</FrontPage>

					<Footer />

				</>} />

			</Routes>

			<div className={'absolute top-0 left-0 right-0 bottom-0 pointer-events-none bg-center bg-cover transition-all '+(hoverBg ? 'opacity-0' : 'opacity-0')} style={hoverBg ? {
					backgroundImage: 'url(/img/'+hoverBg+')'
				} : null
			}></div>

			{
				siteData &&
				<ImagePreloader data={siteData} />
			}

		</div>
	</div>
}

export default App;
