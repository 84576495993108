import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

window.langData = {
	'Verð': 'Prices',
	'Opnunartímar': 'Opening Hours',
	'Til baka á forsíðu': 'Go back',
	'Efni sýningarinnar': 'The Exhibition',
	'Forsíða': 'Front page',
	'Opnunarvika': 'Opening week',
	'16.–24. nóvember': '16–24 November',
	'Aðgangur ókeypis í fyrstu sýningarviku ': 'Free entry during opening week ',

	'Heimur í orðum': 'World in Words',
	'Heimur í': 'World in',
	'orðum': 'Words',

	'Handritasýning í': 'Manuscript Exhibition',
	' Eddu': ' in Edda',

	'Opið í dag': 'Open today',
	'Lokað í dag': 'Closed today',

	'Konungsbók Eddukvæða er til sýnis frá': 'The Codex Regius is on display from',
	'til': 'till',
	'Konungsbók Eddukvæða verður aftur til sýnis frá': 'The Codex Regius will be on display from',
	'Sjá nánar.': 'See more.'
};

window.l = (s) => {
	let lang = 'is';

	try {
		if (localStorage.getItem('currentLang')) {
			lang = window.localStorage.getItem('currentLang');
		}
	}
	catch (e) {
		lang = 'is';
	}

	return lang == 'is' ? s : window.langData[s] ? window.langData[s] : s;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<BrowserRouter>
		<App />
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
