import { useEffect } from "react";
import _ from 'underscore';

function ImagePreloader(props) {
	useEffect(() => {
		let imageUrls = [];

		props.data.forEach(item => {
			if (item.header_background) {
				imageUrls.push(item.header_background);
			}

			if (item.pages) {
				item.pages.forEach(page => {
					if (page.header_background) {
						imageUrls.push(page.header_background);
					}

					if (page.manuscript_image) {
						imageUrls.push(page.manuscript_image);
					}
				});
			}
		});

		window.images = [];

		for (let i = 0; i<imageUrls.length; i++) {
			window.images[i] = new Image();
	        window.images[i].src = imageUrls[i];
		}
	}, [props.data]);
}

export default ImagePreloader;