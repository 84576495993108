import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

import config from '../config';

function LangSwitch(props) {
	const ref = useRef();

	const langSwitch = {
		'is': 'en',
		'en': 'is'
	}

	const langNames = {
		'en': 'English',
		'is': 'Íslenska'
	}

	return <button ref={ref} className={props.className} onClick={() => {
		if (props.onLangChange) {
			props.onLangChange(langSwitch[props.currentLang])
		}
	}}>{props.showLangNames ? langNames[langSwitch[props.currentLang]] : langSwitch[props.currentLang].toUpperCase()}</button>
}

export default LangSwitch;