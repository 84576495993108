import { useEffect, useState } from 'react';
import PlinthMenu from './PlinthMenu';
import OpeningHours from './OpeningHours';
import OpeningCountdown from './OpeningCountdown';
import CodexRegius from './CodexRegius';
import { Link } from 'react-router-dom';
import config from './../config';

function FrontPage(props) {
	const [initialized, setInitialized] = useState(false);
	const [showCodex, setShowCodex] = useState(false);
	const [videoFile, setVideoFile] = useState(null);
	const [title, setTitle] = useState(null);

	const titles = [
		<>Rökstólar, dvergar og helgar kindir</>,
		<>Blóð, slím og svartagall</>,
		<>Þór, Loki, Freyja &#x2013; og Dólgþrasir</>,
		<>Vísdómur, speki og spássíukrot</>,
		<>Hetjur, helgrindur og hrímþursar</>,
		<>Ávísun á fyrirgefningu syndanna</>
	]

	const videos = [
		'gks02-1005-4',
		'gks02-1005-5',
		'gks_3269a_4to_19r',
		'sam_66_6',

		'am02-0105-4',
		'njals_saga_am02-0162_b_delta_1',
		'pappir_sam_66_3',
		'skinn_am02-0132-6',
		'skinn_gks04-1812-b'
	]

	useEffect(() => {
		setTimeout(() => {
			setInitialized(props.initialized);
		}, 600);

		setTimeout(() => {
			setShowCodex(true);
		}, 2100);

		setVideoFile(videos[Math.floor(Math.random()*videos.length)]);
		setTitle(titles[Math.floor(Math.random() * titles.length)]);
	}, [props.initialized]);

	return <div className="min-h-[6800px] md:min-h-[1800px]">
		<div className={'front-wrapper h-[80vh] relative inline-block w-full transition-all duration-[600ms] '+(props.isSubPage ? ' max-h-[600px] duration-[600ms]' : ' max-h-[1200px]')+(initialized ? ' opacity-100' : 'opacity-0')}>

			<div className={'title-wrapper container relative mx-auto mt-40 z-30 flex h-full justify-evenly flex-col pb-[240px]'}>

				{/*
				<div className={'inner-container'}>
					<h1 className="text-sam_accent text-4xl md:text-5xl 4xl:text-6xl">{title}</h1>
				</div>
				*/}

				<div className={'inner-container title text-white transition-[opacity,transform] delay-[300ms] duration-[400ms] '+(initialized ? 'translate-y-0 opacity-100' : '-translate-y-6 opacity-0')}>
					<h1 className={'transition-all duration-[1000ms] uppercase font-medium '+'text-4xl md:text-6xl 2xl:text-[5rem]'}>{window.l('Heimur í')}<br/>{window.l('orðum')}</h1>
					<div className={'transition-all duration-[1000ms] subtitle '+'text-2xl md:text-4xl 2xl:text-6xl'}><span className="text-white">{window.l('Handritasýning í')}</span>{window.l(' Eddu')}</div>					
				</div>

				<div className={'inner-container transition-[opacity,transform] delay-[600ms] duration-[800ms] '+(initialized ? 'translate-y-0 opacity-100' : '-translate-y-6 opacity-0')}>
					<div className="mt-4 text-2xl lg:text-4xl text-white">{window.l('Aðgangur ókeypis í fyrstu sýningarviku ')} <span className="text-sam_accent">{window.l('16.–24. nóvember')}</span></div>
				</div>

				{
					props.headerContent
				}

			</div>

			<div className={'video-wrapper absolute top-0 left-0 right-0 h-[85vh] overflow-hidden'+(props.isSubPage ? ' max-h-[600px] min-h-[600px]' : ' max-h-[1200px] md:min-h-[700px]')+(props.tiltMode ? ' tilt-mode-'+props.tiltMode : '')}>
				<div className="absolute w-full h-full bg-sam_dark/50"></div>
				
				{
					videoFile && <video autoPlay={true} 
						muted={true} 
						loop={true} 
						controls={false}
						playsInline={true}
						poster={'/img/video_fallback.jpg'}
						src={config.videoRoot+videoFile+'.mp4'} 
						className="w-full h-full object-cover" style={{
							backgroundImage: 'url(/img/video_fallback.png)',
							backgroundSize: 'cover',
							backgroundPosition: 'center'
						}}
					>
						<source src={config.videoRoot+videoFile+'.webm'} type="video/webm" />
						<source src={config.videoRoot+videoFile+'.mp4'} type="video/mp4" />
						<img src="/img/video_fallback.jpg" />
					</video>
				}

			</div>

		</div>

		<div className="container mx-auto text-sam_darkblue relative z-30">
			<div className={'inner-container mt-20 mb-40 transition-all duration-[600ms]'+(!props.showContent ? ' max-h-[0px] opacity-0 overflow-hidden' : ' max-h-[5000px] opacity-100')}>
				{
					props.children
				}
			</div>
		</div>
		
	</div>
}

export default FrontPage;