import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import config from './../config';
import { PageContent } from "./Elements";

function FrontPageContent(props) {
	return <>
		{
			props.data && props.data.map((section, index) => <div key={index} 
				className="frontpage-section" ref={ref => {
					if (section.ref_name) {
						props.refObj.current[section.ref_name] = ref;
					}
				}}>
				<div className="text-content">
					{section['content_'+props.currentLang]}
				</div>
			</div>)
		}
	</>
}

export default FrontPageContent;